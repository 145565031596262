import React from "react";

import { Seo } from "../../components";
import Layout from "../../components/layout";
import { BACKEND_BASE_URL } from "../../constant/constants";
import { DepartmentInfo } from "../../components/department-info";

const OrganisationBySlug = ({ serverData, location }) => {
  return (
    <Layout location={location}>
      <Seo
        title={serverData.organisation_info.name}
        description={serverData.organisation_info.markdown}
        image_url={serverData.organisation_info.image_url}
      />
      <DepartmentInfo
        path_prefix="organisations"
        selected_department={serverData.organisation_info.slug}
        department_info={serverData.organisation_info}
        departments_index={serverData.organisations_index}
      />
    </Layout>
  );
};

export async function getServerData(context) {
  try {
    const organisation_info_res = await fetch(
      BACKEND_BASE_URL +
        "departments/department_type-organization/" +
        context.params.slug
    );
    const organisations_index_res = await fetch(
      BACKEND_BASE_URL + "departments/department_type-organization"
    );
    if (!organisation_info_res.ok) {
      throw new Error(`Response failed`);
    }
    if (!organisations_index_res.ok) {
      throw new Error(`Response failed`);
    }
    return {
      props: {
        organisation_info: await organisation_info_res.json(),
        organisations_index: await organisations_index_res.json(),
      },
    };
  } catch (error) {
    console.log(error);
    return {
      status: 500,
      headers: {},
      props: {},
    };
  }
}

export default OrganisationBySlug;
